<template>
  <div class="top-navigation">
    <div
      class="bg-white desktop-nav d-flex align-items-center justify-content-end shadow-sm"
    ></div>
    <div
      class="mobile-nav d-flex justify-content-between bg-brand-dark shadow-sm px-4"
    >
      <div class="d-flex align-items-center justify-content-center">
        <b-img
          src="/media/icons/mobile-brand-image.svg"
          class="brand-image"
          alt="Responsive image"
        ></b-img>
      </div>

      <div class="d-flex align-items-center justify-content-center"></div>
    </div>
    <Transition>
      <div class="navigation-links" v-if="isMobileNavOpen">
        <navigation-links
          :navigationMenu="navigationMenu"
          isMobile
          :isAdminNavigation="!userPanel"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Navigation",
  created() {},
  data() {
    return {
      isMobileNavOpen: false,
    };
  },
  props: {},
  watch: {
    isMobileNavOpen(newValue) {
      if (newValue) {
        document.body.classList.add("overflow-hidden", "vh-100");
      } else {
        document.body.classList.remove("overflow-hidden", "vh-100");
      }
    },
  },
};
</script>
