<template>
  <nav id="sidebar" class="vh-100 d-none d-lg-block">
    <div class="sidebar-header px-3">
      <b-link :to="{ name: 'login' }">
        <div class="d-flex align-items-center justify-content-center">
          <b-img
            src="/media/images/drsg-logo.svg"
            class="brand-image"
            alt="DRSG image"
          ></b-img>
        </div>
      </b-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "UnauthorizedSidebar",
};
</script>
