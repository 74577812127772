<template>
  <div id="wrapper" class="wrapper d-flex align-items-stretch w-100">
    <!-- Sidebar -->
    <unauthorized-sidebar />
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column w-100">
      <!-- Topbar -->
      <unauthorized-top-navigation />
      <!-- Topbar -->
      <div class="content p-x-5">
        <section class="d-flex justify-content-between align-items-center mb-3">
          <p class="card-text">Password reset</p>
        </section>

        <b-card class="card shadow-sm border-0">
          <p class="card-text">
            Create a new password for your account. Make sure it’s strong and
            secure!
          </p>
          <b-form @submit.prevent="onSubmit" class="g-3 needs-validation">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <b-form-group
                  id="newPassword"
                  label="New Password"
                  label-class="form-label required"
                >
                  <b-form-input
                    id="confirmPassword"
                    type="password"
                    data-cy="newPassword"
                    placeholder="Enter new password"
                    v-model="updatePassword.newPassword"
                    :class="{
                      'is-invalid': $v.updatePassword.newPassword.$error,
                    }"
                  ></b-form-input>
                  <b-form-invalid-feedback id="newPassword-feedback">
                    The new password entered does not meet the security
                    requirements
                  </b-form-invalid-feedback>
                  <small
                    data-cy="server-validation-rror"
                    class="text-danger invalid-feedback d-block"
                    v-if="serverValidationError"
                  >
                    {{ serverValidationError }}
                  </small>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <b-form-group
                  label="Confirm New Password"
                  label-class="form-label required"
                >
                  <b-form-input
                    id="confirmPassword"
                    type="password"
                    data-cy="confirmPassword"
                    placeholder="Enter new password"
                    v-model="updatePassword.confirmPassword"
                    :class="{
                      'is-invalid': $v.updatePassword.confirmPassword.$error,
                    }"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="confirmPassword-feedback"
                    v-if="
                      !updatePassword.confirmPassword ||
                      !$v.updatePassword.confirmPassword.valid
                    "
                  >
                    The new password entered does not meet the security
                    requirements
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    id="confirmPassword-feedback"
                    v-if="
                      updatePassword.confirmPassword &&
                      !$v.updatePassword.confirmPassword.matches
                    "
                  >
                    Passwords do not match. Please try again.
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-9">
                <p class="card-text">
                  Password must be at a minimum of 8 characters, with a
                  complexity mix of at least one of each of the following
                  (capital letter, lower case, number, special character (! @ #
                  $ % ^ & *))
                </p>
              </div>
            </div>

            <div class="d-flex flex-row-reverse">
              <b-spinner small v-if="showLoader"></b-spinner>
              <b-button
                type="submit"
                variant="brand-primary"
                data-cy="btn-confirm"
                >Confirm</b-button
              >
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
    <b-modal
      id="update-password-modal"
      centered
      hide-header
      hide-footer
      ref="update-password-modal"
      size="sm"
      no-close-on-backdrop
      data-cy="update-password-modal"
    >
      <div class="d-flex flex-column">
        <div class="message-detail text-center">
          <h6 class="msg-title">Password Updated Successfully</h6>
        </div>
        <p>
          Your password has been updated! You can now log in with your new
          credentials.
        </p>
        <div class="d-flex justify-content-center">
          <a href="/login" class="btn btn-brand-primary"> Back to Login </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UnauthorizedSidebar from "../components/UnauthorizedSidebar.vue";
import UnauthorizedTopNavigation from "../components/UnauthorizedTopNavigation.vue";
import { required, helpers } from "vuelidate/lib/validators";

export default {
  name: "UpdatePassword",
  metaInfo: {
    title: "Reset Password",
  },
  components: {
    UnauthorizedSidebar,
    UnauthorizedTopNavigation,
  },
  data() {
    return {
      showLoader: false,
      token: "",
      emailHash: "",
      serverValidationError: "",
      updatePassword: {
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  created() {
    this.token = encodeURIComponent(this.$route.params.token);
    this.emailHash = encodeURIComponent(this.$route.params.email);
    this.checkResetPasswordExpiryLink();
  },
  methods: {
    ...mapActions("auth", ["verifyResetPasswordLinkExpiry", "changePassword"]),
    onSubmit() {
      this.serverValidationError = "";
      this.$v.updatePassword.$touch();
      if (this.$v.updatePassword.$invalid) {
        return;
      }
      this.checkResetPasswordExpiryLink();
      this.changePassword({
        token: this.token,
        emailHash: this.emailHash,
        newPassword: this.updatePassword.newPassword,
      }).then((response) => {
        if (response.status === 200) {
          this.$refs["update-password-modal"].show();
        } else if (response.status === 400) {
          this.serverValidationError = response?.message;
        } else {
          this.showLoader = false;
          this.$router.push({
            path:
              "/login?code=" +
              response.status +
              "&message=" +
              response?.message,
          });
        }
      });
    },
    checkResetPasswordExpiryLink() {
      this.verifyResetPasswordLinkExpiry({
        token: this.token,
        emailHash: this.emailHash,
      }).then((response) => {
        if (response.status === 400) {
          this.$router.push({
            path:
              "/login?code=" +
              response.status +
              "&message=" +
              response?.message,
          });
        } else if (response.status === 500) {
          this.$router.push({
            path:
              "/login?code=" +
              response.status +
              "&message=" +
              response?.message,
          });
        }
      });
    },
  },
  validations: {
    updatePassword: {
      newPassword: {
        required,
        valid: helpers.regex(
          "validPassword",
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/
        ),
      },
      confirmPassword: {
        required,
        valid: helpers.regex(
          "validPassword",
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/
        ),
        matches: helpers.withParams(
          { type: "passwordMatch" },
          function (value, parentVm) {
            if (!value || !parentVm?.newPassword) return true;
            return value === parentVm?.newPassword;
          }
        ),
      },
    },
  },
};
</script>
