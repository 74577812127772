var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper d-flex align-items-stretch w-100",attrs:{"id":"wrapper"}},[_c('unauthorized-sidebar'),_c('div',{staticClass:"d-flex flex-column w-100",attrs:{"id":"content-wrapper"}},[_c('unauthorized-top-navigation'),_c('div',{staticClass:"content p-x-5"},[_vm._m(0),_c('b-card',{staticClass:"card shadow-sm border-0"},[_c('p',{staticClass:"card-text"},[_vm._v(" Create a new password for your account. Make sure it’s strong and secure! ")]),_c('b-form',{staticClass:"g-3 needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('b-form-group',{attrs:{"id":"newPassword","label":"New Password","label-class":"form-label required"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.updatePassword.newPassword.$error,
                  },attrs:{"id":"confirmPassword","type":"password","data-cy":"newPassword","placeholder":"Enter new password"},model:{value:(_vm.updatePassword.newPassword),callback:function ($$v) {_vm.$set(_vm.updatePassword, "newPassword", $$v)},expression:"updatePassword.newPassword"}}),_c('b-form-invalid-feedback',{attrs:{"id":"newPassword-feedback"}},[_vm._v(" The new password entered does not meet the security requirements ")]),(_vm.serverValidationError)?_c('small',{staticClass:"text-danger invalid-feedback d-block",attrs:{"data-cy":"server-validation-rror"}},[_vm._v(" "+_vm._s(_vm.serverValidationError)+" ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('b-form-group',{attrs:{"label":"Confirm New Password","label-class":"form-label required"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.updatePassword.confirmPassword.$error,
                  },attrs:{"id":"confirmPassword","type":"password","data-cy":"confirmPassword","placeholder":"Enter new password"},model:{value:(_vm.updatePassword.confirmPassword),callback:function ($$v) {_vm.$set(_vm.updatePassword, "confirmPassword", $$v)},expression:"updatePassword.confirmPassword"}}),(
                    !_vm.updatePassword.confirmPassword ||
                    !_vm.$v.updatePassword.confirmPassword.valid
                  )?_c('b-form-invalid-feedback',{attrs:{"id":"confirmPassword-feedback"}},[_vm._v(" The new password entered does not meet the security requirements ")]):_vm._e(),(
                    _vm.updatePassword.confirmPassword &&
                    !_vm.$v.updatePassword.confirmPassword.matches
                  )?_c('b-form-invalid-feedback',{attrs:{"id":"confirmPassword-feedback"}},[_vm._v(" Passwords do not match. Please try again. ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-9"},[_c('p',{staticClass:"card-text"},[_vm._v(" Password must be at a minimum of 8 characters, with a complexity mix of at least one of each of the following (capital letter, lower case, number, special character (! @ # $ % ^ & *)) ")])])]),_c('div',{staticClass:"d-flex flex-row-reverse"},[(_vm.showLoader)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"brand-primary","data-cy":"btn-confirm"}},[_vm._v("Confirm")])],1)])],1)],1)],1),_c('b-modal',{ref:"update-password-modal",attrs:{"id":"update-password-modal","centered":"","hide-header":"","hide-footer":"","size":"sm","no-close-on-backdrop":"","data-cy":"update-password-modal"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"message-detail text-center"},[_c('h6',{staticClass:"msg-title"},[_vm._v("Password Updated Successfully")])]),_c('p',[_vm._v(" Your password has been updated! You can now log in with your new credentials. ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-brand-primary",attrs:{"href":"/login"}},[_vm._v(" Back to Login ")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"d-flex justify-content-between align-items-center mb-3"},[_c('p',{staticClass:"card-text"},[_vm._v("Password reset")])])
}]

export { render, staticRenderFns }